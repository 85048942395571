import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Image from '../images/blog_wealth_gap.jpg'


export class PostItem extends React.Component {

  render() {
  return (
  <div className="csl_item">
    <Link to={this.props.link} title={this.props.heading}>
    <div className="csl_container">
      <div className="csl_banner" style={{
          backgroundImage: 'url(/'+this.props.image+')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
      }}>
      </div>
      <div className="csl_text">
        <div className="csl_postitem">
        <div className="csl_heading">
        {this.props.heading}
        </div>
        <div className="csl_description">
        {this.props.description ? this.props.description : ''}
        </div>
        <div className="csl_readtime">
        {this.props.readtime}
        </div>
        </div>
      </div>
    </div>
    </Link>
  </div>

  )}
}

export default PostItem
